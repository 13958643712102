<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-card class="col-8">
        <b-form @submit.prevent="createPlan">
          <b-form-group label="Name:" label-for="namePlan">
            <b-form-input
              v-model="namePlan"
              id="namePlan"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Description:" label-for="descriptionPlan">
            <b-form-textarea
              v-model="descriptionPlan"
              id="descriptionPlan"
              required
            ></b-form-textarea>
          </b-form-group>
          <div label="Name:" label-for="namePlan" ref="inputGroup">
            <template>
              <div
                v-for="(input, index) in inputValues"
                :key="index"
                class="form-group"
              >
                <label :for="'contentItem' + index"
                  >Feature {{ index + 1 }}</label
                >
                <div class="input-group">
                  <b-form-input
                    :id="'contentItem' + index"
                    v-model="input.value"
                    required
                    :placeholder="'Feature ' + (index + 1)"
                    class="form-control"
                  ></b-form-input>
                  <div class="input-group-append">
                    <button
                      @click="removeInput(index)"
                      type="button"
                      class="btn btn-outline-danger"
                    >
                      <feather-icon trash-icon icon="TrashIcon" />
                    </button>
                  </div>
                </div>
              </div>
            </template>
            <b-button
              @click="addInput"
              variant="outline-success"
              block
              class="w-50 d-block mx-auto"
              >Add another feature</b-button
            >
          </div>

          <b-form-group label="Interval:" label-for="interval">
            <b-form-select
              v-model="interval"
              :options="intervalOptions"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Interval Count:" label-for="intervalCount">
            <b-form-input
              v-model="intervalCount"
              id="intervalCount"
              required
            ></b-form-input>
          </b-form-group>
        
          <b-form-group label="Price :" label-for="planPrice">
            <b-form-input
              type="number"
              step="0.01"
              v-model="price"
              id="planPrice"
              required
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary" class="d-block mx-auto">
            <span v-if="!isLoading">Create Plan</span>
            <b-spinner v-else variant="light" label="Spinning"></b-spinner>
          </b-button>
        </b-form>
      </b-card>
    </b-row>
  </b-container>
</template>
    
<script>
import {
  BFormGroup,
  BForm,
  BButton,
  BFormTextarea,
  BFormInput,
  BCard,
  BRow,
  BCol,
  BContainer,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormGroup,
    BForm,
    BButton,
    BFormTextarea,
    BFormInput,
    BCard,
    BRow,
    BCol,
    BContainer,
    BFormSelect,
    BSpinner,
  },
  data() {
    return {
      namePlan: null,
      descriptionPlan: null,
      intervalCount: null,
      interval: null,
      productIdOptions: null,
      intervalOptions: [
        { value: "DAY", text: "Payement Per Day" },
        { value: "WEEK", text: "Payement Per Week" },
        { value: "MONTH", text: "Payement Per Month" },
        { value: "YEAR", text: "Payement Per Year" },
      ],
      productId: null,
      price: null,
      inputValues: [{ value: "" }],
      isLoading: false,
    };
  },
  mounted() {
    this.getAllProductIds();
  },
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    getAllProductIds() {
      axios
        .get("/paypal-list-produit")
        .then(({ data }) => {
          this.productId = data.data_db;
          let productIdOptions = this.productId.map((pI) => {
            return { key: pI.produit_id, value: pI.name };
          });
          this.productIdOptions = productIdOptions.reduce(
            (acc, { key, value }) => {
              acc[key] = value;
              return acc;
            },
            {}
          );
        })
        .catch((err) => {
          console.err(err);
        });
    },

    createPlan() {
      let features = [];
      for (let i = 0; i < this.inputValues.length; i++) {
        features.push(this.inputValues[i].value);
      }
      const formData = {
        name_plan: this.namePlan,
        descreption_plan: this.descriptionPlan,
        interval_duration: this.intervalCount,
        interval_type: this.interval,
        price: this.price,
        features: features,
      };
      this.isLoading = true;
      axios
        .post("/add-plan", formData)
        .then(({ data }) => {
          if (data.success) {
            this.showToast("success", "SaveIcon", "Success", data.message);
          } else {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              data.message
            );
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.showToast("danger", "AlertTriangleIcon", "Error", error);
          this.isLoading = false;
        });
    },
    addInput() {
      try {
        this.inputValues.push({ value: "" });
        this.$nextTick(() => {
          const inputs = this.$refs.inputGroup.querySelectorAll("input");
          const lastInput = inputs[inputs.length - 1];
          lastInput.focus();
        });
      } catch (error) {}
    },
    removeInput(index) {
      this.inputValues.splice(index, 1);
    },
  },
  beforeRouteEnter(to, from, next) {
    axios.post("/isAdmin").then(({ data }) => {
      if (data.status === true) {
        next(true);
      } else if (data.status === false) {
        next("/error-404");
      }
    }).catch((error)=>{
      next("/login")
    });
  },
};
</script>
    
<style scoped></style>
    